/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

/* Style the submit button with a specific background color etc */
input[type=submit] {
    background-color: teal;
    color: white;
    padding: 10px 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: rgb(50, 182, 169);
}

h3 {
    text-align: center;
}
